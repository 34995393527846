import store from "../stores";

/**
 * Gate : allowIfNotAuthenticated
 *
 * Only allow this route if the user is LOGGED OUT
 * - Join
 * - Login
 * - Password Reset
 */
const allowIfNotAuthenticated = (to, from, next) => {
    if (!store.getters["session/IS_AUTHENTICATED"]) {
        next();
        return;
    }
    next("/accounts-tracked");
};
/**
 * Gate : allowIfAuthenticated
 *
 * Only:
 * - /oauth/--/callback
 */
const allowIfAuthenticated = (to, from, next) => {
    if (store.getters["session/IS_AUTHENTICATED"]) {
        next();
        return;
    }
    next("/accounts-tracked");
};

/**
 * Gate : allowIfNotActive
 *
 * Only:
 * - /register
 * - /labs/register
 */
const allowIfNotActive = (to, from, next) => {
    if (
        [1, 2, 3, 5].indexOf(store.getters["us/PLAN_TYPE_ID"]) > -1 &&
        store.getters["us/STATUS_ID"] == 3
    ) {
        next("/accounts-tracked");
        return;
    }

    if (
        [4].indexOf(store.getters["us/PLAN_TYPE_ID"]) > -1 &&
        [3, 4].indexOf(store.getters["us/PLAN_TYPE_ID"]) > -1
    ) {
        next("/labs");
        return;
    }
    next();
};

/**
 * Gate : allowIfActive
 *
 * Only allow this route if the account is ACTIVE
 */
const allowIfActive = (to, from, next) => {
    if (!store.getters["session/IS_AUTHENTICATED"]) {
        next("/login");
        return;
    }

    if (
        [1, 2, 3, 5].indexOf(store.getters["us/PLAN_TYPE_ID"]) > -1 &&
        store.getters["us/STATUS_ID"] &&
        store.getters["us/STATUS_ID"] !== 3 &&
        to.path !== "/register"
    ) {
        next("/register");
        return;
    }

    if (
        [4].indexOf(store.getters["us/PLAN_TYPE_ID"]) > -1 &&
        store.getters["us/STATUS_ID"] &&
        [3, 4].indexOf(store.getters["us/STATUS_ID"]) < 0 &&
        to.path !== "/labs/register"
    ) {
        next("/labs/register");
        return;
    }

    if (
        [4].indexOf(store.getters["us/PLAN_TYPE_ID"]) > -1 &&
        !localStorage.getItem("i")
    ) {
        next("/labs");
        return;
    }

    next();
};

const allowIfLabs = (to, from, next) => {
    // They are registering for labs
    if (
        !store.getters["session/IS_AUTHENTICATED"] &&
        to.path !== "/labs/register"
    ) {
        next("/login");
        return;
    }

    // not labs, send to main app
    if ([1, 2, 3].indexOf(store.getters["us/PLAN_TYPE_ID"]) > -1) {
        next("/accounts-tracked");
        return;
    }

    // labs but being configured/setup
    if ([4].indexOf(store.getters["us/STATUS_ID"]) > -1) {
        next("/labs/configuring");
        return;
    }

    next();
};

export default {
    mode: "history",
    routes: [
        // Labs Microsite
        {
            path: "/labs/cancel",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/cancel.vue");
            },
            beforeEnter: allowIfLabs,
        },
        {
            path: "/labs/configuring",
            meta: {
                layout: "LayoutExternal",
            },
            component: function () {
                return import("@/js/components/views/labs/configuring.vue");
            },
        },
        {
            path: "/labs/credit-card",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/credit-card.vue");
            },
            beforeEnter: allowIfLabs,
        },
        {
            path: "/labs/crm",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/crm.vue");
            },
            beforeEnter: allowIfLabs,
        },
        {
            path: "/labs",
            name: "Labs",
            meta: {
                layout: "LayoutLabs",
                section: null,
                title: "labsHome",
            },
            component: function () {
                return import("@/js/components/views/labs/home.vue");
            },
            beforeEnter: allowIfLabs,
        },
        {
            path: "/labs/invoices",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/invoices.vue");
            },
            beforeEnter: allowIfLabs,
        },
        {
            path: "/labs/javascript",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/javascript.vue");
            },
            beforeEnter: allowIfLabs,
        },
        {
            path: "/labs/login",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/login.vue");
            },
            beforeEnter: allowIfLabs,
        },
        {
            path: "/labs/password",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/password.vue");
            },
            beforeEnter: allowIfLabs,
        },
        {
            path: "/labs/register",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/register.vue");
            },
            beforeEnter: allowIfNotActive,
        },
        {
            path: "/labs/segment-com",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/segment-com.vue");
            },
            beforeEnter: allowIfLabs,
        },
        {
            path: "/labs/settings",
            meta: {
                layout: "LayoutLabs",
            },
            component: function () {
                return import("@/js/components/views/labs/settings.vue");
            },
            beforeEnter: allowIfLabs,
        },
        // Allow if Not Authenticated
        {
            path: "/join-account",
            name: "joinAccount",
            meta: {
                layout: "LayoutExternal",
                section: null,
                title: "joinAccount",
            },
            component: function () {
                return import("@/js/components/views/join-account.vue");
            },
            props: ($route) => {
                return {
                    invitationToken: $route.query.invitation_token,
                };
            },
            beforeEnter: allowIfNotAuthenticated,
        },
        {
            path: "/login",
            name: "logIn",
            meta: {
                layout: "LayoutExternal",
                section: null,
                title: "login",
            },
            component: function () {
                return import("../components/views/log-in.vue");
            },
            props: function ($route) {
                return {
                    sessionRepository:
                        new (require("@/js/repositories/session.js"))(),
                };
            },
            beforeEnter: allowIfNotAuthenticated,
        },
        {
            path: "/reset-password",
            name: "resetPassword",
            meta: {
                layout: "LayoutExternal",
                section: null,
                title: "resetPassword",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/reset-password.vue");
                },
            },
            props: {
                default: ($route) => {
                    return {};
                },
            },
            beforeEnter: allowIfNotAuthenticated,
        },
        {
            path: "/reset-password/:token",
            name: "confirmPasswordReset",
            meta: {
                layout: "LayoutExternal",
                section: null,
                title: "resetPassword",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/reset-password-confirm.vue"
                    );
                },
            },
            props: {
                default: ($route) => {
                    return {
                        token: $route.params.token,
                    };
                },
            },
            beforeEnter: allowIfNotAuthenticated,
        },
        {
            path: "/access-grants",
            name: "accessGrants",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "accessGrants",
                title: "accessGrants",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/access-grants");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: function ($route) {},
        },
        {
            path: "/error",
            name: "error",
            meta: {
                layout: "LayoutInternal",
                section: "system",
                title: "error",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/system-message");
                },
            },
        },
        {
            path: "/page-not-found",
            name: "pageNotFound",
            meta: {
                layout: "LayoutInternal",
                section: "system",
                title: "pageNotFound",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/system-message");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        code: 404,
                    };
                },
            },
        },
        {
            path: "/register",
            name: "register",
            meta: {
                layout: "LayoutExternal",
                section: null,
                title: "register",
            },
            component: function () {
                return import("@/js/components/views/register.vue");
            },
            beforeEnter: allowIfNotActive,
        },
        // Allow if Authenticated
        {
            path: "/",
            redirect: "/accounts-tracked",
            beforeEnter: allowIfActive,
        },
        {
            path: "/account-preferences",
            name: "account-preferences",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "accountPreferences",
                title: "accountPreferences",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/account-preferences");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: function ($route) {},
        },
        {
            path: "/accounts/segments/:id",
            name: "accountSegment",
            meta: {
                layout: "LayoutInternal",
                section: "accounts",
                title: "accountSegment",
                helpArticleIds: [
                    "5ede56b404286306f804d20e",
                    "5edf869b2c7d3a10cba89e77",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/accounts-segment");
                },
                subnav: function () {
                    return import("@/js/components/partials/accounts-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        segmentsRepository:
                            new (require("@/js/repositories/segments.js"))(),
                        accountsRepository:
                            new (require("@/js/repositories/base.js"))(
                                window.akita.urls.api + "/v1/accounts"
                            ),
                        accountSearchRepository:
                            new (require("@/js/repositories/account-search.js"))(),
                        segmentId: parseInt($route.params.id),
                    };
                },
                subnav: ($route) => {
                    return {
                        meSegmentsRepository:
                            new (require("@/js/repositories/me-segments.js"))(),
                        segmentsRepository:
                            new (require("@/js/repositories/segments.js"))(),
                    };
                },
            },
        },
        {
            path: "/account-segment-errors",
            name: "accountSegmentErrors",
            meta: {
                layout: "LayoutInternal",
                section: "accounts",
                title: "segmentErrors",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/account-segment-errors"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/accounts-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: function ($route) {},
        },
        {
            path: "/accounts/segments/:id/edit",
            name: "accountSegmentEdit",
            meta: {
                layout: "LayoutInternal",
                section: "accounts",
                helpArticleIds: [
                    "5ede56b404286306f804d20e",
                    "5edf869b2c7d3a10cba89e77",
                ],
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/accounts-segment-edit"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/accounts-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        segmentsRepository:
                            new (require("@/js/repositories/segments.js"))(),
                        accountsRepository:
                            new (require("@/js/repositories/base.js"))(
                                window.akita.urls.api + "/v1/accounts"
                            ),
                        accountSearchRepository:
                            new (require("@/js/repositories/account-search.js"))(),
                        segmentId: parseInt($route.params.id),
                    };
                },
                subnav: ($route) => {
                    return {
                        meSegmentsRepository:
                            new (require("@/js/repositories/me-segments.js"))(),
                        segmentsRepository:
                            new (require("@/js/repositories/segments.js"))(),
                    };
                },
            },
        },
        {
            path: "/accounts/:id/:section?/:subsection?",
            name: "account",
            meta: {
                layout: "LayoutInternal",
                section: "accounts",
                title: "account",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/account");
                },
                subnav: function () {
                    return import("@/js/components/partials/accounts-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/account-embed/:section?",
            name: "accountEmbedSection",
            meta: {
                layout: "LayoutExternal",
                section: "accounts",
                title: "account",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/account-embed");
                },
            },
        },
        {
            path: "/accounts-tracked",
            name: "accountsTracked",
            meta: {
                layout: "LayoutInternal",
                section: "accounts",
                title: "accountsTracked",
                helpArticleIds: ["5edf42232c7d3a10cba89a92"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/accounts-tracked");
                },
                subnav: function () {
                    return import("@/js/components/partials/accounts-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {},
        },
        {
            path: "/accounts-churned",
            name: "accountsChurned",
            meta: {
                layout: "LayoutInternal",
                section: "accounts",
                title: "accountsChurned",
                helpArticleIds: ["5edf42232c7d3a10cba89a92"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/accounts-churned");
                },
                subnav: function () {
                    return import("@/js/components/partials/accounts-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {},
        },
        {
            path: "/accounts",
            name: "accounts",
            meta: {
                layout: "LayoutInternal",
                section: "accounts",
                title: "accounts",
                helpArticleIds: ["5edf42232c7d3a10cba89a92"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/accounts");
                },
                subnav: function () {
                    return import("@/js/components/partials/accounts-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {},
        },
        {
            path: "/activities",
            redirect: "/activities/today",
            beforeEnter: allowIfActive,
        },
        {
            path: "/activities/today",
            name: "activitiesToday",
            meta: {
                title: "activitiesToday",
                layout: "LayoutInternal",
                section: "activities",
                helpArticleIds: [
                    "606d96114466ce6ddc5f2018",
                    "606db3ce4466ce6ddc5f20a7",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/activities-today");
                },
                subnav: function () {
                    return import("@/js/components/partials/activities-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        activitiesRepository:
                            new (require("@/js/repositories/activities.js"))(),
                    };
                },
                subnav: ($route) => {
                    return {};
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/activities/overdue",
            name: "activitiesOverdue",
            meta: {
                layout: "LayoutInternal",
                section: "activities",
                title: "activitiesOverdue",
                helpArticleIds: [
                    "606d96114466ce6ddc5f2018",
                    "606db3ce4466ce6ddc5f20a7",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/activities-overdue");
                },
                subnav: function () {
                    return import("@/js/components/partials/activities-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {};
                },
                subnav: ($route) => {
                    return {};
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/activities/upcoming",
            name: "activitiesUpcoming",
            meta: {
                layout: "LayoutInternal",
                section: "activities",
                title: "activitiesUpcoming",
                helpArticleIds: [
                    "606d96114466ce6ddc5f2018",
                    "606db3ce4466ce6ddc5f20a7",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/activities-upcoming");
                },
                subnav: function () {
                    return import("@/js/components/partials/activities-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        activitiesRepository:
                            new (require("@/js/repositories/activities.js"))(),
                    };
                },
                subnav: ($route) => {
                    return {};
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/activities/completed",
            name: "activitiesCompleted",
            meta: {
                layout: "LayoutInternal",
                section: "activities",
                title: "activitiesCompleted",
                helpArticleIds: [
                    "606d96114466ce6ddc5f2018",
                    "606db3ce4466ce6ddc5f20a7",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/activities-completed");
                },
                subnav: function () {
                    return import("@/js/components/partials/activities-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        activitiesRepository:
                            new (require("@/js/repositories/activities.js"))(),
                    };
                },
                subnav: ($route) => {
                    return {};
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/activities/following",
            name: "activitiesFollowing",
            meta: {
                layout: "LayoutInternal",
                section: "activities",
                title: "activitiesFollowing",
                helpArticleIds: [
                    "606d96114466ce6ddc5f2018",
                    "606db3ce4466ce6ddc5f20a7",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/activities-following");
                },
                subnav: function () {
                    return import("@/js/components/partials/activities-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        activitiesRepository:
                            new (require("@/js/repositories/activities.js"))(),
                        meActivitiesFollowedRepository:
                            new (require("@/js/repositories/me-activities-followed.js"))(),
                    };
                },
                subnav: ($route) => {
                    return {};
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/activities/calendar",
            name: "activitiesCalendar",
            meta: {
                layout: "LayoutInternal",
                section: "activities",
                title: "activitiesCalendar",
                helpArticleIds: [
                    "606d96114466ce6ddc5f2018",
                    "606db3ce4466ce6ddc5f20a7",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/activities-calendar");
                },
                subnav: function () {
                    return import("@/js/components/partials/activities-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        activitiesRepository:
                            new (require("@/js/repositories/activities.js"))(),
                    };
                },
                subnav: ($route) => {
                    return {};
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/activities/grid",
            name: "activitiesGrid",
            meta: {
                layout: "LayoutInternal",
                section: "activities",
                title: "activitiesGrid",
                helpArticleIds: [
                    "606d96114466ce6ddc5f2018",
                    "606db3ce4466ce6ddc5f20a7",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/activities-grid");
                },
                subnav: function () {
                    return import("@/js/components/partials/activities-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        activitiesRepository:
                            new (require("@/js/repositories/activities.js"))(),
                    };
                },
                subnav: ($route) => {
                    return {};
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/activities/:id",
            name: "activity",
            meta: {
                layout: "LayoutInternal",
                section: "activities",
                title: "Activity",
                helpArticleIds: [
                    "606db3ce4466ce6ddc5f20a7",
                    "606d96114466ce6ddc5f2018",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/activity");
                },
                subnav: function () {
                    return import("@/js/components/partials/activities-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/api-tokens",
            name: "apiTokens",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "apiTokens",
                title: "apiTokens",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/api-tokens");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                meRepository: require("@/js/repositories/me.js"),
            },
        },
        {
            path: "/computed-properties",
            name: "computedProperties",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "computedProperties",
                title: "computedProperties",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/computed-properties");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/contacts/segments/:id",
            name: "contactSegment",
            meta: {
                layout: "LayoutInternal",
                section: "contacts",
                helpArticleIds: [
                    "5ede56b404286306f804d20e",
                    "5edf869b2c7d3a10cba89e77",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/contacts-segment");
                },
                subnav: function () {
                    return import("@/js/components/partials/contacts-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        segmentsRepository:
                            new (require("@/js/repositories/segments.js"))(),
                        contactsRepository:
                            new (require("@/js/repositories/base.js"))(
                                window.akita.urls.api + "/v1/accounts"
                            ),
                        contactSearchRepository:
                            new (require("@/js/repositories/contact-search.js"))(),
                        segmentId: parseInt($route.params.id),
                    };
                },
                subnav: ($route) => {
                    return {
                        segmentsRepository:
                            new (require("@/js/repositories/segments.js"))(),
                        segmentId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/contacts/segments/:id/edit",
            name: "contactSegmentEdit",
            meta: {
                layout: "LayoutInternal",
                section: "contacts",
                title: "segmentEdit",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/contacts-segment-edit"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/contacts-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        segmentId: parseInt($route.params.id),
                    };
                },
                subnav: ($route) => {
                    return {};
                },
            },
        },
        {
            path: "/contacts",
            name: "contacts",
            meta: {
                layout: "LayoutInternal",
                section: "contacts",
                title: "contacts",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/contacts");
                },
                subnav: function () {
                    return import("@/js/components/partials/contacts-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/contacts/:id/:section?",
            name: "contact",
            meta: {
                layout: "LayoutInternal",
                section: "contacts",
                title: "contact",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/contact");
                },
                subnav: function () {
                    return import("@/js/components/partials/contacts-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/contact-segment-errors",
            name: "contactSegmentErrors",
            meta: {
                layout: "LayoutInternal",
                section: "contacts",
                title: "segmentErrors",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/contact-segment-errors"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/contacts-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: function ($route) {},
        },
        {
            path: "/credit-card",
            name: "credit-card",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "billing",
                title: "creditCard",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/credit-card");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/csv-upload",
            name: "csv-upload",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "integrations",
                title: "csvUpload",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/csv-upload");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/custom-fields",
            name: "custom-fields",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "customFields",
                title: "customFields",
                helpArticleIds: [
                    "5ff58da0fd168b7773532013",
                    "60795a468af76a714bfd7dc2",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/custom-fields");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: function ($route) {},
        },
        {
            path: "/custom-fields/:id",
            name: "custom-field-edit",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "customFields",
                helpArticleIds: [
                    "5ff58da0fd168b7773532013",
                    "60795a468af76a714bfd7dc2",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/custom-field-edit");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        integrationTraitId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/dashboards",
            name: "dashboards",
            meta: {
                layout: "LayoutInternal",
                section: "dashboards",
                title: "dashboards",
                helpArticleIds: [
                    "5f2140a604286306f8078d3f",
                    "5ee0cda204286306f804f191",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/dashboards");
                },
                subnav: function () {
                    return import("@/js/components/partials/dashboards-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {};
                },
                subnav: ($route) => {
                    return {
                        dashboardId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/dashboards/:id",
            name: "dashboard",
            meta: {
                layout: "LayoutInternal",
                section: "dashboards",
                helpArticleIds: [
                    "5f2140a604286306f8078d3f",
                    "5ee0cda204286306f804f191",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/dashboard");
                },
                subnav: function () {
                    return import("@/js/components/partials/dashboards-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        dashboardId: parseInt($route.params.id),
                    };
                },
                subnav: ($route) => {
                    return {};
                },
            },
        },
        {
            path: "/domain-verifications",
            name: "verifiedSendingDomains",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "domainVerifications",
                title: "domainVerifications",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/domain-verifications");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/feature-requests/:any?",
            name: "featureRequests",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                title: "featureRequests",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/feature-requests");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/health-scores",
            name: "healthScores",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "healthScores",
                title: "healthScores",
                helpArticleIds: ["5f6b4a04cff47e00168f9774"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/health-scores");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/health-scores/:id",
            name: "healthScore",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "healthScores",
                helpArticleIds: ["5f6b4a04cff47e00168f9774"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/health-score");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        segmentId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/health-scores/:id/components/:component_id",
            name: "healthScoreComponent",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "healthScores",
                helpArticleIds: ["5f6b4a04cff47e00168f9774"],
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/health-score-component"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        segmentId: parseInt($route.params.id),
                        componentId: parseInt($route.params.component_id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/health-scores/:id/edit",
            name: "healthScoreEdit",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "healthscores",
                helpArticleIds: ["5f6b4a04cff47e00168f9774"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/health-score-edit");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        segmentId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/health-scores/:id/delete",
            name: "healthScoreDelete",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "healthscores",
                helpArticleIds: ["5f6b4a04cff47e00168f9774"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/health-score-delete");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        segmentId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/health-scores/:id/thresholds",
            name: "healthScoreThresholds",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "healthScores",
                helpArticleIds: [
                    "5f6b4a64cff47e00168f9779",
                    "5f6b4a04cff47e00168f9774",
                ],
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/health-score-thresholds"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        segmentId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/health-scores/:id/components",
            name: "healthScoreComponents",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "healthScores",
                helpArticleIds: [
                    "5f6b4afc4cedfd0017637543",
                    "5f6b4a04cff47e00168f9774",
                ],
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/health-score-components"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        segmentId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/health-scores/:id/entities",
            name: "healthScoreEntities",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "healthScores",
                helpArticleIds: [
                    "5f6b4b444cedfd00173babb7",
                    "5f6b4a04cff47e00168f9774",
                ],
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/health-score-entities"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        segmentId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/invitations",
            name: "invitations",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "invitations",
                title: "invitations",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/invitations");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/invoices",
            name: "invoices",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "billing",
                title: "invoices",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/invoices");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/javascript",
            name: "javascript",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "javascript",
                title: "javascript",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/javascript");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/logout",
            name: "logOut",
            meta: {
                layout: "LayoutExternal",
            },
            component: function () {
                return import("../components/views/log-out.vue");
            },
        },
        {
            path: "/mailboxes",
            name: "mailboxes",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "mailboxes",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/mailboxes");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                meRepository: require("@/js/repositories/me.js"),
            },
        },
        {
            path: "/mapped-attributes",
            name: "mappedAttributes",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "mappedAttributes",
                title: "mappedAttributes",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/mapped-attributes");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/integrations",
            name: "integrations",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "integrations",
                title: "integrations",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/integrations");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/integrations/:id",
            name: "integration",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "integrations",
                title: "integration",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/integration");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        integrationId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/message-templates",
            name: "messageTemplates",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "messageTemplates",
                title: "messageTemplates",
                helpArticleIds: [
                    "601c1b7cfb34b55df443d512",
                    "6041f95f0a2dae5b58fb633c",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/message-templates");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/message-templates/:id",
            name: "messageTemplate",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "messageTemplates",
                helpArticleIds: [
                    "6041fae8661b720174a73ae3",
                    "6041fa11b3ebfb109b588d7d",
                    "601c1b7cfb34b55df443d512",
                    "6041f95f0a2dae5b58fb633c",
                ],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/message-template");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        messageTemplateId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/metrics",
            name: "metrics",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "metrics",
                title: "metrics",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/metrics");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/metrics/:id",
            name: "metrics-edit",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "metrics",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/metric-edit");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        metricId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/notifications",
            name: "notifications",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "notifications",
                title: "notifications",
                helpArticleIds: ["5eb1795f2c7d3a5ea54a7c78"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/notifications");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/notifications/:uuid/callback",
            name: "notficiationsCallback",
            meta: { layout: "LayoutInternal" },
            components: {
                default: function () {
                    return import("@/js/components/views/oauth-callback-user");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        uuid: $route.params.uuid,
                        redirect: "/notifications",
                    };
                },
            },
        },
        {
            path: "/oauth/:uuid/callback",
            name: "oauthCallback",
            meta: { layout: "LayoutExternal" },
            components: {
                default: function () {
                    return import("@/js/components/views/oauth-callback");
                },
            },
            beforeEnter: allowIfAuthenticated,
            props: {
                default: ($route) => {
                    return {
                        uuid: $route.params.uuid,
                    };
                },
            },
        },
        {
            path: "/profile/slack/callback",
            name: "slackCallback",
            meta: { layout: "LayoutInternal" },
            components: {
                default: function () {
                    return import("@/js/components/views/oauth-callback-user");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        uuid: $route.params.uuid,
                    };
                },
            },
        },
        {
            path: "/password",
            name: "password",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "password",
                title: "password",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/password");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-logs",
            name: "playbooksLogs",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksLogs",
                title: "playbooksLogs",
                helpArticleIds: [],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbooks-logs");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-summary",
            name: "playbooksSummary",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksSummary",
                title: "playbooksSummary",
                helpArticleIds: ["5ffc2c11fd168b77735335fb"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbooks-summary");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-users",
            name: "playbooksUsers",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksUsers",
                title: "playboooksUsers",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbooks-users");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-users/:id",
            name: "playbooksUser",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksUsers",
                title: "playboooksUser",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbooks-user");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        userId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks",
            name: "playbooksList",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
                title: "playboooksList",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbooks");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks/:id/summary-instances",
            name: "playbookSummaryInstances",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
                helpArticleIds: ["5ffc2c11fd168b77735335fb"],
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbook-summary-instances"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks/:id/performance",
            name: "playbookPerformance",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbook-performance");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks/:id/edit",
            name: "playbookEdit",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
                helpArticleIds: ["6041f70e8502d1120e90df12"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbook-edit");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/playbooks/:id/steps",
            name: "playbookSteps",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
                helpArticleIds: ["6012b662b9a8501b295d4fc4"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbook-steps");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/playbooks/:id/triggers",
            name: "playbookTriggers",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
                title: "playbookTriggers",
                helpArticleIds: ["6012b662b9a8501b295d4fc4"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbook-triggers");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/playbooks/:id/delete",
            name: "playbookDelete",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
                title: "playbookDelete",
                helpArticleIds: ["6012b662b9a8501b295d4fc4"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbook-delete");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/playbooks/:id/list",
            name: "playbookList",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList	",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbook-list");
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks/:id/summary-tasks",
            name: "playbookSummaryTasks",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbook-summary-tasks"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-users/:id/instances-statuses",
            name: "playbooksUsersInstancesStatuses",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksUsers",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbooks-user-instances-statuses"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                        userId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-users/:id/instances-events",
            name: "playbooksUsersInstancesEvents",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksUsers",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbooks-user-instances-events"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                        userId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks/:id/instances-statuses",
            name: "playbookInstancesStatuses",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbook-instances-statuses"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks/:id/instances-events",
            name: "playbookInstancesEvents",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbook-instances-events"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-instances-statuses",
            name: "playbooksInstancesStatuses",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksSummary",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbooks-instances-statuses"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-instances-events",
            name: "playbooksInstancesEvents",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksSummary",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbooks-instances-events"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbook-instances/:id",
            name: "playbookInstance",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/playbook-instance");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        segmentId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/policy-acceptances",
            name: "policyAcceptances",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/policy-acceptances");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks/:id/tasks-statuses",
            name: "playbookTasksStatuses",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbookTasks",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbook-tasks-statuses"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks/:id/tasks-events",
            name: "playbookTasksEvents",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksList",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbook-tasks-events"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                        playbookId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-tasks-statuses",
            name: "playbooksTasksStatuses",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksSummary",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbooks-tasks-statuses"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-tasks-events",
            name: "playbooksTasksEvents",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksSummary",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbooks-tasks-events"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-users/:id/tasks-statuses",
            name: "playbooksUserTasksStatuses",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksUsers",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbooks-user-tasks-statuses"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                        userId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/playbooks-users/:id/tasks-events",
            name: "playbooksUserTasksEvents",
            meta: {
                layout: "LayoutInternal",
                section: "playbooks",
                subSection: "playbooksUsers",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/playbooks-user-tasks-events"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/playbooks-subnav");
                },
            },
            props: {
                default: ($route) => {
                    return {
                        status: $route.query.status,
                        dateStart: $route.query.date_start,
                        dateEnd: $route.query.date_end,
                        dateRangeTypeId: $route.query.date_range_type_id,
                        userId: parseInt($route.params.id),
                    };
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/profile",
            name: "profile",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "profile",
                title: "profile",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/profile");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                meRepository: require("@/js/repositories/me.js"),
            },
        },
        {
            path: "/slack",
            name: "slack",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "slack",
                title: "slack",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/slack");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                meRepository: require("@/js/repositories/me.js"),
            },
        },
        {
            path: "/providers",
            name: "providers",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "providers",
                title: "providers",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/providers");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/providers/:id",
            name: "provider",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "providers",
                title: "provider",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/provider");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        providerId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/providers/:providerId/connect/:integrationId?",
            name: "providerConnect",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "providers",
                title: "provider",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/provider-connect");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        providerId: parseInt($route.params.providerId),
                        integrationId: parseInt($route.params.integrationId),
                    };
                },
            },
        },
        {
            path: "/roles",
            name: "roles",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "roles",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/roles");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/roles/:id",
            name: "role",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "roles",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/role");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        roleId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/segment",
            name: "segment",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "segment",
                title: "segmentCom",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/segment-com");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/subscription",
            name: "subscription",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "subscription",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/subscription");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/tags",
            name: "tags",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "tags",
                title: "tags",
                helpArticleIds: ["5ff59b36fd168b7773532065"],
            },
            components: {
                default: function () {
                    return import("@/js/components/views/tags");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                meRepository: require("@/js/repositories/me.js"),
            },
        },
        {
            path: "/task-types",
            name: "taskTypes",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "taskTypes",
                title: "taskTypes",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/task-types");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/configure-churned-accounts",
            name: "configureChurnedAccounts",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "configureChurnedAccounts",
                title: "configureChurnedAccounts",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/configure-churned-accounts"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/configure-tracked-accounts",
            name: "configureTrackedAccounts",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "configureTrackedAccounts",
                title: "configureTrackedAccounts",
            },
            components: {
                default: function () {
                    return import(
                        "@/js/components/views/configure-tracked-accounts"
                    );
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/user-preferences",
            name: "userPreferences",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "userPreferences",
                title: "userPreferences",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/user-preferences");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: function ($route) {},
        },
        {
            path: "/users/:id",
            name: "user-edit",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "users",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/user");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        userId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/users/:id/permissions",
            name: "user-roles",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "users",
                title: "userPermissions",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/user-permissions");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
            props: {
                default: ($route) => {
                    return {
                        userId: parseInt($route.params.id),
                    };
                },
            },
        },
        {
            path: "/users",
            name: "users",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "users",
                title: "users",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/users");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/webhooks",
            name: "webhooks",
            meta: {
                layout: "LayoutInternal",
                section: "settings",
                subSection: "webhooks",
                title: "webhooks",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/webhooks");
                },
                subnav: function () {
                    return import("@/js/components/partials/settings-subnav");
                },
            },
            beforeEnter: allowIfActive,
        },
        {
            path: "/temp/hotkeys",
            name: "temp-hotkeys",
            beforeEnter: allowIfActive,
            meta: {
                layout: "LayoutInternal",
                section: "temp",
                title: "tempHotkeys",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/temp-hotkeys");
                },
                subnav: function () {
                    return import("@/js/components/partials/temp-subnav");
                },
            },
        },
        {
            path: "/temp/widgets",
            name: "temp-widgets",
            beforeEnter: allowIfActive,
            meta: {
                layout: "LayoutInternal",
                section: "temp",
                title: "tempWidgets",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/temp-widgets");
                },
                subnav: function () {
                    return import("@/js/components/partials/temp-subnav");
                },
            },
        },
        {
            path: "/temp/style-guide",
            name: "temp-style-guide",
            beforeEnter: allowIfActive,
            meta: {
                layout: "LayoutInternal",
                section: "temp",
                title: "tempStyleGuide",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/temp-style-guide");
                },
                subnav: function () {
                    return import("@/js/components/partials/temp-subnav");
                },
            },
        },
        {
            path: "/temp/permissions",
            name: "temp-permissions",
            beforeEnter: allowIfActive,
            meta: {
                layout: "LayoutInternal",
                section: "temp",
                title: "tempPermissions",
            },
            components: {
                default: function () {
                    return import("@/js/components/views/temp-permissions");
                },
                subnav: function () {
                    return import("@/js/components/partials/temp-subnav");
                },
            },
        },
    ],
};
